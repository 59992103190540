<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>公文管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品订单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!-- 按钮区域 -->
      <div class="btns">
        <el-button type="primary" icon="el-icon-document-checked" class="btn_1" @click="addCommd">商品核销</el-button>
        <!-- 筛选 -->
        <el-select style="margin-left: 20px;" v-model="value" placeholder="请选择筛选条件">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <!-- 商品列表 -->
      <el-table :height="'30rem'" :data="commodityList" border style="width: 100%">
        <el-table-column label="商品名称" width="180">
          <template slot-scope="scope">
            <div style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
              {{scope.row.product?scope.row.product.proName:scope.row.groupPro.groupName}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品图片" width="180">
          <template slot-scope="scope">
            <div>
              <img style="width: 100px;height: 60px;object-fit: contain;"
                :src="scope.row.product?scope.row.product.proPic:scope.row.groupPro.groupPic" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="describe" label="商品总价" width="180">
          <template slot-scope="scope">
            <div style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
              {{scope.row.product?scope.row.product.proPrice:scope.row.groupPro.currentYield}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单总价">
          <template slot-scope="scope">
            <div style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
              {{scope.row.product?scope.row.orderPrice:scope.row.groupPro.menDianPrice}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="下单时间">
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination style="margin: 15px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[5,10,20]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
      <!-- 弹出层 -->
      <el-dialog @close="close" :title="currentDialog" :visible.sync="centerDialogVisible" width="50%" center>
        <div>
          <div style="height: 50vh;overflow-y:scroll;">
            <!-- 商品名称 -->
            <div class="dialogItem">
              <!-- 搜索框 -->
              <div style="margin-top: 15px;display: flex;width: 100%;justify-content: center;">
                <el-input @input="searchInput" @blur="blur" ref="input" style="width:70%" placeholder="请输入商品码或用扫码枪录入"
                  v-model="input3" class="input-with-select">
                  <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
              </div>
            </div>
            <!-- 订单内容 -->
            <div style="width: 100%;height: 70%;margin-top: 30px;font-size:18px;">
              <div v-if="item">
                <!-- 商品名称 -->
                <div style="display: flex;align-items: center;margin-bottom: 20px;">
                  <div style="width: 100px;">商品名称：</div>
                  <div>{{item.proName?item.proName:item.groupName}}</div>
                </div>
                <!-- 商品图片 -->
                <div style="display: flex;align-items: center;margin-bottom: 20px;">
                  <div style="width: 100px;">商品图片：</div>
                  <img style="width: 250px;" :src="item.proPic?item.proPic:item.groupPic" alt="">
                </div>
                <!-- 商品价格 -->
                <div style="display: flex;align-items: center;margin-bottom: 20px;">
                  <div style="width: 100px;">商品价格：</div>
                  <div>{{item.proPrice?item.proPrice:item.menDianPrice}}</div>
                </div>
              </div>
              <div v-else style="display: flex;align-items:center;justify-content: center;"> 暂无订单信息</div>
            </div>
          </div>
          <div style="display:flex;justify-content:space-around">
            <el-button @click="centerDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitMap">确 定</el-button>
          </div>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        input3: '',// 订单码
        commodityList: [],
        page: 1,
        limit: 5,// 每页显示
        total: 0,//总条数
        optionsList: [
          {
            name: '',
            num: '',
            per: '',
          }
        ],// 项目列表展示
        currentDialog: '',//弹框状态
        centerDialogVisible: false,// 弹框开关
        imgurl: 'https://www.idemer.com/web/upload/file',
        // imgurl: 'http://192.168.3.165:8069/upload/file',
        fileList: [],// 商品图片文件列表
        fileList2: [],// 图文详情文件列表
        textarea: '',// 商品描述
        comName: '',//商品名称
        price: '',// 商品价格
        currentItem: '',// 当前编辑项
        item: '',// 当前查询订单
        options: [{
          value: '1',
          label: '全部'
        }],
        value: '1',// 筛选条件
      }
    },
    created() {
      this.getList()
    },
    filters: {
      srcImg(row) {
        return row.proPic.split(',')[0]
      }
    },
    methods: {
      // 失去焦点后再次获取焦点
      blur() {
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      },
      // 查询订单详情
      searchInput() {
        if (this.input3.length != 10) return
        this.$http.post(`/pro/selectPro`, null, {
          params: {
            id: this.input3
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.item = res.data.data
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 扫码枪扫码
      getCode() {
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
        var code = "";
        var lastTime, nextTime;
        var lastCode, nextCode;
        console.log(111)
        document.onkeypress = function (e) {
          nextCode = e.code;
          nextTime = new Date().getTime();
          // 扫码器的录入速度远快过手动录入，两个字符间隔小于20MS判定录入设备为扫码器
          if (lastCode != null && lastTime != null && nextTime - lastTime <= 20) {
            code += String.fromCharCode(nextCode);
          } else if (lastCode != null && lastTime != null && nextTime - lastTime > 100) {
            code = "";
          }
          lastCode = nextCode;
          lastTime = nextTime;
          this.input3 = code;
        }
      },
      // 添加项目列表子项
      addOptionsList() {
        if (this.optionsList.length > 10) {
          this.$message.error('服务项目列表最多添加10个！')
          return
        }
        this.optionsList.push(
          {
            name: '',
            num: '',
            per: '',
          }
        )
      },
      // 删除项目列表子项
      deleteOptionsList(index) {
        this.optionsList.splice(index, 1)
      },
      // 获取商品列表
      getList() {
        this.$http.post(`/company/getAllPro`, null, {
          params: {
            current: this.page,
            size: this.limit,
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.commodityList = res.data.data.list
            this.total = res.data.data.all
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 核销商品
      addCommd() {
        this.centerDialogVisible = true
        this.currentDialog = '商品核销'
        this.getCode()
      },
      // 核销商品
      handleEdit(index, row) {
        this.centerDialogVisible = true
        this.currentDialog = '商品核销'
        this.input3 = row.proUnique
        this.searchInput()
      },
      // 删除
      handleDelete(index, row) {
        this.$confirm('确认删除该商品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(`/pro/delete`, null, {
            params: {
              id: row.id
            }
          }).then(result => {
            if (result.data.code == 200) {
              this.$message.success(result.data.msg)
              this.getList()
            }
          })
        });
      },
      //每页大小改变
      handleSizeChange(newSize) {
        this.limit = newSize
        this.getList()
      },
      // 弹框关闭
      close() {
        this.comName = ''
        this.fileList = []
        this.currentItem = ''
        this.textarea = ''
        this.price = ''
        this.input3 = ''
        this.item = ''
      },
      // 当前页改变
      handleCurrentChange(newPage) {
        this.page = newPage
        this.getList()
      },
      // 确定
      submitMap() {
        if (this.item && this.input3) {
          this.$http.post(`/company/hexiao`, null, {
            params: {
              id: this.input3,
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              this.centerDialogVisible = false
              this.getList()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.error('请扫描或输入订单码查询订单信息')
        }
      },
      // 上传图片成功
      handleAvatarSuccess(res, file, fileList) {
        console.log(fileList)
        this.fileList = fileList
      },
      // 删除图片
      handleRemove(file, fileList) {
        this.fileList = fileList
      },
      // 上传图片成功
      handleAvatarSuccess2(res, file, fileList) {
        console.log(fileList)
        this.fileList2 = fileList
      },
      // 删除图片
      handleRemove2(file, fileList) {
        this.fileList2 = fileList
      },
    }
  }
</script>

<style scoped lang="less">
  .dialogItem {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .title {
      width: 100px;
    }
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>